import {defineStore} from 'pinia';
import {useNuxtApp} from '#app';
import {useRoute} from 'vue-router';
import {useApi} from '~/composables/api';

export const useNews = defineStore('news', {
	state: () => ({
		activeTab: 'news',
		isOpenedPopupSearchTags: false,
		isOpenedPopupSearchSources: false,
		route: null,
		previousRoute: null,
		filterId: null,
		filters: {
			tags: [],
			services: [],
			sources: [],
			marketType: null
		},
		lastFilters: null,
		favorites: {
			services: new Set(),
			sources: new Set(),
		},
		tags: [],
		recentTags: [],
		influencers: [],
		folderServices: {
			'1': {
				id: '1',
				name: 'Articles',
				slug: 'articles',
				isFavorited: false,
				isChecked: 0,
				isOpened: false,
				isShowed: true,
				countCheckedChildren: 0,
				shortInfluencers: [],
				influencers: []
			},
			'2': {
				id: '2',
				name: 'TikTok',
				slug: 'tiktok',
				isFavorited: false,
				isChecked: 0,
				isOpened: false,
				isShowed: true,
				countCheckedChildren: 0,
				shortInfluencers: [],
				influencers: []
			},
			'3': {
				id: '3',
				name: 'YouTube',
				slug: 'youtube',
				isFavorited: false,
				isChecked: 0,
				isOpened: false,
				isShowed: true,
				countCheckedChildren: 0,
				shortInfluencers: [],
				influencers: []
			},
			'4': {
				id: '4',
				name: 'X (Twitter)',
				slug: 'x-twitter',
				isFavorited: false,
				isChecked: 0,
				isOpened: false,
				isShowed: true,
				countCheckedChildren: 0,
				shortInfluencers: [],
				influencers: []
			},
			'5': {
				id: '5',
				name: 'Telegram',
				slug: 'telegram',
				isFavorited: false,
				isChecked: 0,
				isOpened: false,
				isShowed: true,
				countCheckedChildren: 0,
				shortInfluencers: [],
				influencers: []
			},
			'6': {
				id: '6',
				name: 'Medium',
				slug: 'medium',
				isFavorited: false,
				isChecked: 0,
				isOpened: false,
				isShowed: true,
				countCheckedChildren: 0,
				shortInfluencers: [],
				influencers: []
			},
		},
	}),
	actions: {
		initChecked() {
			// console.log('news.initChecked()');
			// for (const serviceId in this.folderServices) {
			// 	this.folderServices[serviceId].isFavorited = this.favorites.services.has(serviceId);
			// }

			for (const influencer of this.influencers) {
				// console.log('influencer', Object.keys(influencer?.services).length)

				if (Object.keys(influencer?.services).length) {

					for (let serviceId in influencer.services) {
						// console.log('this.folderServices[serviceId - 1]', serviceId, typeof serviceId, this.folderServices[serviceId])

						influencer.services[serviceId][0].isChecked = false;

						if (this.favorites.services.has(serviceId)) {
							influencer.services[serviceId][0].isFavorited = true;
						} else {
							influencer.services[serviceId][0].isFavorited = this.favorites.sources.has(influencer.services[serviceId][0].id);
						}

						if (this.folderServices[serviceId]) {
							// this.folderServices[serviceId].isFavorited = this.favorites.services.has(serviceId);
							this.folderServices[serviceId].influencers.push({
								ref: influencer,
								isOpened: false,
								isShowed: true
							});
						}
					}
				}
			}
		},
		initFavorites() {
			// console.log('news.initChecked()');
			for (const serviceId in this.folderServices) {
				this.folderServices[serviceId].isFavorited = this.favorites.services.has(serviceId);
			}

			for (const influencer of this.influencers) {
				if (Object.keys(influencer?.services).length) {

					for (let serviceId in influencer.services) {
						if (this.favorites.services.has(serviceId)) {
							influencer.services[serviceId][0].isFavorited = true;
						} else {
							influencer.services[serviceId][0].isFavorited = this.favorites.sources.has(influencer.services[serviceId][0].id);
						}
					}
				}
			}
		},

		// FAVORITES
		onSwitchFavorite(serviceId, sourceId = null, value) {
			// console.log('onSwitchFavorite()', serviceId, sourceId, value)
			const app = useNuxtApp();
			const favorites = {
				services: new Set([...this.favorites.services]),
				sources: new Set([...this.favorites.sources]),
			};

			if (sourceId) {
				this.setFavoriteSource(serviceId, sourceId, value);

				// Switch source favorite
				let countChecked = 0;
				let countUnchecked = 0;
				const arrCheckedSources = new Set();
				const countInfluencers = this.folderServices[serviceId].influencers.length;

				if (countInfluencers) {
					for (const influencer of this.folderServices[serviceId].influencers) {

						if (influencer.ref.services[serviceId][0].isFavorited) {
							countChecked++;
							arrCheckedSources.add(influencer.ref.services[serviceId][0].id);
						} else {
							countUnchecked++;
						}
					}
					// console.log('countChecked', countChecked)
					// console.log('countUnchecked', countUnchecked)
					if (countChecked === countInfluencers) {
						this.folderServices[serviceId].isFavorited = true;
					} else if (countUnchecked === countInfluencers) {
						this.folderServices[serviceId].isFavorited = false;
					} else {
						this.folderServices[serviceId].isFavorited = false;
					}
				}

				// console.log('arrCheckedSources', arrCheckedSources)
				if (this.folderServices[serviceId].isFavorited) {
					if (arrCheckedSources.size) {
						favorites.sources = app.$helpers.differenceSets(favorites.sources, arrCheckedSources);
					}

					favorites.services.add(serviceId);
				} else {

					if (arrCheckedSources.size) {
						favorites.sources = app.$helpers.unionSets(favorites.sources, arrCheckedSources);
					}

					if (value) {
						favorites.sources.add(sourceId);
					} else {
						favorites.sources.delete(sourceId);
					}

					favorites.services.delete(serviceId);
				}

			} else {
				// Switch service favorite
				if (value) {
					const value = true;
					const arrDeleteSources = new Set();
					this.folderServices[serviceId].isFavorited = value;

					for (const influencer of this.folderServices[serviceId].influencers) {
						if (influencer.ref.services[serviceId][0].isFavorited === value) {
							arrDeleteSources.add(influencer.ref.services[serviceId][0].id);
						}
						influencer.ref.services[serviceId][0].isFavorited = value;
					}

					console.log('arrDeleteSources', arrDeleteSources);

					if (arrDeleteSources.size) {
						favorites.sources = app.$helpers.differenceSets(favorites.sources, arrDeleteSources);
					}

					favorites.services.add(serviceId);

				} else {
					favorites.services.delete(serviceId);
					this.setFavoriteSources(serviceId, false);
				}
			}

			this.favorites = favorites;
		},
		setFavoriteSource(serviceId, sourceId, value) {
			// console.log('setFavoriteSource()', serviceId, typeof serviceId, sourceId, value)
			for (const influencer of this.folderServices[serviceId].influencers) {
				if (influencer.ref.services[serviceId][0].id === sourceId) {
					influencer.ref.services[serviceId][0].isFavorited = value;
				}
			}
		},
		setFavoriteSources(serviceId, value) {
			// console.log('setFavoriteSources()', serviceId, value)
			if (serviceId) {
				for (const influencer of this.folderServices[serviceId].influencers) {
					if (influencer) {
						influencer.ref.services[serviceId][0].isFavorited = value;
					}
				}
			}
		},

		// FILTERS
		setFilters(filters) {
			// console.log('setFilters()', filters)
			if (filters?.market_type) {
				this.filters.marketType = filters.market_type;
			} else {
				this.filters.marketType = null;
			}

			for (const serviceId in this.folderServices) {
				if (filters?.services?.includes(Number(serviceId))) {
					this.folderServices[serviceId].isChecked = 1;
					this.setCheckboxesSources(serviceId);
				} else {
					this.folderServices[serviceId].isChecked = 0;
					this.folderServices[serviceId].countCheckedChildren = 0;
				}
			}

			if (filters?.sources?.length) {
				for (const influencer of this.influencers) {
					if (Object.keys(influencer?.services).length) {
						for (let serviceId in influencer.services) {
							// console.log('serviceId', serviceId, filters.sources.includes(influencer.services[serviceId][0].id))

							if (filters?.sources?.includes(influencer.services[serviceId][0].id)) {
								influencer.services[serviceId][0].isChecked = true;
								this.folderServices[serviceId].isChecked = 2;
								this.folderServices[serviceId].countCheckedChildren++;
							} else {
								influencer.services[serviceId][0].isChecked = false;
							}
						}
					}
				}
			}

			if (filters?.tags?.length) {
				this.filters.tags = this.tags.filter(item => filters.tags.includes(item.id));
			} else {
				this.filters.tags = [];
			}

			this.findCheckedAndSetFilters();
		},
		setMarket(marketId) {
			// console.log('setService()', serviceId)
			this.filters.marketType = marketId;
			// this.filters = JSON.parse(JSON.stringify(this.filters));

			this.setURL();
		},
		setService(serviceId) {
			// console.log('setService()', serviceId)
			this.folderServices[serviceId].isChecked = 1;

			this.setCheckboxesSources(serviceId);
			this.findCheckedAndSetFilters();
			this.setURL();
		},
		setInfluencer(influencerId) {
			// console.log('setInfluencer()', influencerId)
			this.clearFilters();

			const influencer = this.influencers.find(item => item.id === influencerId);

			for (const serviceId in influencer.services) {
				this.folderServices[serviceId].isChecked = 2;
				influencer.services[serviceId][0].isChecked = true;
			}

			this.findCheckedAndSetFilters();
		},
		setTags(tags) {
			const filters = JSON.parse(JSON.stringify(this.filters));

			if (tags?.length) {
				filters.tags = this.tags.filter(item => tags.includes(item.id));
			} else {
				filters.tags = [];
			}

			if (JSON.stringify(this.filters) !== JSON.stringify(filters)) {
				this.lastFilters = JSON.stringify(this.filters);
				this.filters = filters;
			}
		},
		setRecentTags(tags) {
			this.recentTags = tags;
		},
		addRecentTags(tags) {
			const recentTags = JSON.parse(JSON.stringify(this.recentTags));

			for (const tag of tags) {
				const index = recentTags.findIndex(item => item.id === tag.id);

				if (index !== -1) {
					recentTags.splice(index, 1);
				}
				recentTags.unshift(tag);
			}

			if (recentTags.length > 10) {
				recentTags.splice(10);
			}

			this.recentTags = recentTags;
		},
		clearFilters() {
			// console.log('clearFilters()')
			this.uncheckedServicesAndSources();

			const filters = JSON.parse(JSON.stringify(this.filters));
			filters.services = [];
			filters.sources = [];
			filters.tags = [];
			this.filters = filters;
			this.filterId = null;

			// this.setURL();
		},
		checkEmptyFilters() {
			return this.filters.marketType === null && this.filters.services.length === 0 && this.filters.sources.length === 0 && this.filters.tags.length === 0;
		},
		onChangeFolderCheckbox(serviceId) {
			// console.log('onChangeFolderCheckbox()', serviceId)

			this.setCheckboxesSources(serviceId);
			// this.findCheckedAndSetFilters();
			// this.setURL();
		},
		onChangeCheckbox(serviceId) {
			// console.log('onChangeCheckbox()', serviceId)

			this.checkCheckedFolder(serviceId);
			// this.findCheckedAndSetFilters();
			// this.setURL();
		},
		removeTag(tag) {
			switch (tag.type) {
				case 'service': {
					this.folderServices[tag.id].isChecked = 0;

					this.setCheckboxesSources(tag.id);
					this.findCheckedAndSetFilters();
					break;
				}

				case 'source': {
					for (const influencer of this.folderServices[tag.service_type].influencers) {
						if (influencer.ref.services[tag.service_type][0].id === tag.id) {
							influencer.ref.services[tag.service_type][0].isChecked = false;
						}
					}

					this.checkCheckedFolder(tag.service_type);
					this.findCheckedAndSetFilters();
					break;
				}

				case 'tag': {
					const index = this.filters.tags.findIndex(item => item.id === tag.id)

					if (index !== -1) {
						this.filters.tags.splice(index, 1);
						// const filters = JSON.parse(JSON.stringify(this.filters));
						//
						// filters.tags.splice(index, 1);
						// this.filters = filters;
					}
					break;
				}
			}

			this.setURL();
		},
		uncheckedServicesAndSources() {
			// console.log('onReset()')

			for (const serviceId in this.folderServices) {
				this.folderServices[serviceId].isChecked = 0;
				this.folderServices[serviceId].countCheckedChildren = 0;
			}

			for (const influencer of this.influencers) {
				for (const serviceId in influencer.services) {
					influencer.services[serviceId][0].isChecked = false;
				}
			}
		},
		setCheckboxesSources(serviceId) {
			// console.log('setCheckboxesSources()', serviceId)
			if (serviceId) {
				let countChecked = 0;

				for (const influencer of this.folderServices[serviceId].influencers) {
					influencer.ref.services[serviceId][0].isChecked = !!this.folderServices[serviceId].isChecked;

					if (influencer.ref.services[serviceId][0].isChecked) {
						countChecked++;
					}
				}

				this.folderServices[serviceId].countCheckedChildren = countChecked;
				// console.log('this.folderServices[serviceId].countCheckedChildren', this.folderServices[serviceId].countCheckedChildren)
			}
		},
		checkCheckedAllFolders() {
			// console.log('checkCheckedAllFolders()')
			for (const serviceId in this.folderServices) {
				this.checkCheckedFolder(serviceId);
			}
		},
		checkCheckedFolder(serviceId) {
			// console.log('checkCheckedFolder()', serviceId)
			let countChecked = 0;
			let countUnchecked = 0;
			const countInfluencers = this.folderServices[serviceId].influencers.length;

			if (countInfluencers) {
				for (const influencer of this.folderServices[serviceId].influencers) {

					if (influencer.ref.services[serviceId][0].isChecked) {
						countChecked++;
					} else {
						countUnchecked++;
					}
				}

				this.folderServices[serviceId].countCheckedChildren = countChecked;

				if (countChecked === countInfluencers) {
					this.folderServices[serviceId].isChecked = 1;
				} else if (countUnchecked === countInfluencers) {
					this.folderServices[serviceId].isChecked = 0;
				} else {
					this.folderServices[serviceId].isChecked = 2;
				}
			}
		},
		findCheckedServices() {
			// console.log('findCheckedServices()')
			let foundServices = [];

			for (const serviceId in this.folderServices) {
				if (this.folderServices[serviceId].isChecked === 1) {
					foundServices.push({
						// sources: influencer.services[serviceId],
						id: Number(serviceId),
						type: 'service',
						name: this.folderServices[serviceId].name,
						slug: this.folderServices[serviceId].slug,
					});
				}
			}
			// console.log('findCheckedServices()', foundServices)

			return foundServices;
		},
		findCheckedSources() {
			// console.log('findCheckedSources()')
			let foundSources = [];

			for (const influencer of this.influencers) {
				for (const serviceId in influencer.services) {
					// console.log(serviceId, this.folderServices[serviceId].isChecked, influencer.name, influencer.services[serviceId][0].isChecked)
					// console.log(this.folderServices[serviceId])
					if (this.folderServices[serviceId].isChecked === 2 && influencer.services[serviceId][0].isChecked) {
						foundSources.push({
							// sources: influencer.services[serviceId],
							id: influencer.services[serviceId][0].id,
							type: 'source',
							service_type: serviceId,
							influencer: {
								id: influencer.id,
								name: influencer.name
							},
							slug: influencer.slug
						})
					};
				}
			}
			// console.log('findCheckedSources()', foundSources)

			return foundSources;
		},
		isFiltersUpdated() {
			return this.lastFilters !== JSON.stringify(this.filters);
		},
		async findCheckedAndSetFilters() {
			// console.log('findCheckedAndSetFilters()')
			const foundServices = this.findCheckedServices();
			const foundSources = this.findCheckedSources();
			const filters = JSON.parse(JSON.stringify(this.filters));

			// console.log('foundServices', foundServices, foundSources);

			filters.services = foundServices;
			filters.sources = foundSources;

			if (JSON.stringify(this.filters) !== JSON.stringify(filters)) {
				this.lastFilters = JSON.stringify(this.filters);
				this.filters = filters;
			}
			// console.log('filters', filters)
			// await this.setURL(this.filters);
		},

		async setURL() {
			const filters = this.filters;
			// console.log('%csetURL', 'color:red', filters);
			// console.log('lastFilters', this.lastFilters)
			// console.log('Filters    ', JSON.stringify(this.filters))
			// if (this.lastFilters === JSON.stringify(this.filters)) {
			// 	console.log('filter don\'t changes')
			// 	return;
			// }

			this.lastFilters = JSON.stringify(this.filters);

			const api= useApi();
			const app = useNuxtApp();
			const router = app.$router;
			const route = this.route;
			// console.log('route', route)

			const isOneMarket = filters.marketType && !filters.services.length && !filters.sources.length && !filters.tags.length;
			const isOneService = !filters.marketType && filters.services.length === 1 && !filters.sources.length && !filters.tags.length;
			const isOneTag = !filters.marketType && !filters.services.length && !filters.sources.length && filters.tags.length === 1;
			let isOneOrMoreSource = !filters.marketType && !filters.services.length && filters.sources.length && !filters.tags.length;

			if (isOneOrMoreSource) {
				const slug = filters.sources[0].slug;

				isOneOrMoreSource = filters.sources.every(item => item.slug === slug);
			}

			if (isOneMarket) {
				console.log('PUSH market')
				router.push(`/news/${filters.marketType === 1 ? 'crypto' : 'nft'}/`);

			} else if (isOneService) {
				console.log('PUSH service')
				router.push(`/news/${filters.services[0].slug}/`);

			} else if (isOneTag) {
				console.log('PUSH tag')
				router.push(`/news/t/${filters.tags[0].slug}/`);

			} else if (isOneOrMoreSource) {
				console.log('PUSH source')
				router.push(`/news/${filters.sources[0].slug}/`);

			} else if (this.checkEmptyFilters(filters)) {
				console.log('PUSH news')
				router.push(`/news/`);
			} else {
				console.log('PUSH filter', route?.query?.filter, this.filterId)

				// if (!route.query?.filter?.length || route.query?.filter === this.filterId) {
					const filterId = await api.news.fetchFilterId(filters);
					console.log('new FilterId', filterId)

					router.push(`/news/?filter=${filterId}`);
					this.filterId = filterId;
				// } else {
				// 	this.filterId = route.query.filter;
				// }
				// } else {
					// switch (route.name) {
					// 	case 'news-articles':
					// 		this.news.setFilters({
					// 			services: ['1']
					// 		});
					// 		break;
					// }
				// }
			}
		}
	},
})

